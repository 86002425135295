.Base {
  background: var(--jc-neutrals-white, #fff);
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* padding-bottom: 16px; */
  z-index: 1;
  color: #0A2240;
}
.Header {
  margin: 8px 16px 12px 16px;
  display: flex;
  align-items: center;
  flex: 1;
}

.Title {
  /* JC/Title/Title 1 - Bold */
  
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 127.778% */
}

.TopContainer {
  display: flex;
  min-height: 150px;
  max-height: 150px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 2px 12px 0px #617ca833;
  text-align: start;
}
.SectionalWrapper {
  margin-top: 10px;
  padding: 8px 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: scroll;
}
.SectionContentItem {
  text-align: center;
  min-width: 72px;
  padding: 0 5px;
}
.ItemPlace {
  font-size: 14px;
  line-height: 13.2px;
  color: #84909F;
  font-weight: 400;
}
.ItemPlaceRace {
  margin-top: 8px;
  max-width: 72px;
  font-size: 14px;
  line-height: 13.2px;
  color: #84909F;
  font-weight: 400;
}
.ItemContent {
  margin-top: 8px;
  font-size: 14px;
  line-height: 19.2px;
  font-weight: 400;
}
.ItemExtra {
  margin-top: 2px;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.2px;
  color: #9DA6B2;
}
.divider {
  height: 100%;
  width: 1px;
  background-color: #e0e0e0;
}

.SectionalWrapper2 {
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.SectionContentItemFirstColumn {
  position: sticky;
  min-width: 72px;
  box-shadow: 13px 0 10px -10px #ccc;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.SectionContentItem2 {
  text-align: center;
  min-width: 72px;
}
.SectionContentItem2 p {
  /* margin: 0; */
}
.SectionRightContent {
  display: inline-flex;
  flex: 1;
  width: calc(100vh - 32px);
  overflow-x: scroll;
  min-height: 77px;
  justify-content: space-between;
  /* scrollbar-width: none; 
    -ms-overflow-style: none;   */
}
/* .SectionRightContent::-webkit-scrollbar { 
  width: 0;
  height: 0;
}  */
