.VideoDialog{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: #000;
    z-index: 1;
}
.VideoDialog-header{
    height: 60px;
    position: absolute;
    z-index: 5;
    transition: all 0.3s;
}
.VideoDialog-header svg{
    color: #fff;
    float: left;
    padding: 24px 20px;
}

.videoCustomBar-marsk{
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 80px;
    width: 100%;

    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 1) 100%);
    opacity: 0.7;
    box-shadow: 0px -4px 10px rgb(50 50 50 / 75%);
}   
.videoCustomBar-container {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 80px;
    width: 100%;
    z-index: 1;
    transition: all 0.3s;
}

.videoCustomBar-item {
    display: flex;
    justify-content: space-between;
    color: #fff;
    flex-basis: 50%;
    align-items: center;
    padding: 0 16px;
}
.videoCustomBar-item input{
    width: 100%;
}

.VideoLoading{
    position: absolute;
    top: calc(50% - 25px);
    z-index: 2;
    left: calc(50% - 25px);
}

input[type=range] {
    height: 5px;
    appearance: none;
    /* background: #fff; */
}
input[type=range]:focus {

}
input[type=range]::-webkit-slider-runnable-track {
    appearance: none;
    background: #ccc;
    height: 5px;
}
input[type=range]::-webkit-slider-thumb {
    appearance: none;
    background: #fff;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    transform: translateY(-5px);
}
input[type=range]:focus::-webkit-slider-runnable-track {
    appearance: none;
    background: #3071A9;
}
input[type=range]::-moz-range-track {

}
input[type=range]::-moz-range-thumb {

}
input[type=range]::-ms-track {

}
input[type=range]::-ms-fill-lower {

}
input[type=range]::-ms-fill-upper {

}
input[type=range]::-ms-thumb {

}
input[type=range]:focus::-ms-fill-lower {
}
input[type=range]:focus::-ms-fill-upper {
}