.Base {
  z-index: 1;
  color: #0A2240;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.formDetailNameContainer {
  margin-top: 16px;
  display: flex;
  padding: 0 12px 0 12px;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 12px;
}
.HorseAvatar {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
  border: 2px solid rgba(255, 255, 255);
}
.NameText {
  font-size: 18px;
  text-align: center;
  /* JC/Title/Title 1 - Bold */
  margin-left: 8px;

}
.table-wrapper {
  height: 100%;
  background-color: white;
  overflow: hidden;
  /* border-radius: 28px 28px 0 0; */
}
.tableExtra {
  text-align: left;
  padding: 8px;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: #fff;
}
.tableExtra p {
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
}
.tableExtra span {
  font-size: 11px;
  font-weight: 700;
  line-height: 15px;
}
.horseWrapper {
  background-color: #fff;
  padding: 16px 12px;
  display: flex;
  align-items: center;
}
