
@media screen and (orientation: landscape) {
    @media screen and (min-width: 768px) {
        /* CSS rules for landscape orientation and screens between 769px and 1024px wide */
        .TabletBaseContainer{
            padding-left: 18%;
            padding-right: 18%;
        }
    }
}
@media screen and (orientation: portrait) {
    @media screen and (min-width: 768px) {
        /* CSS rules for landscape orientation and screens between 769px and 1024px wide */
        .TabletBaseContainer{
            padding-left: 15%;
            padding-right: 15%;
        }
    }
}

@media screen and (min-width: 768px) {
    /* Owner Profile */
    .owner-profile-other-container-owner{
        padding: 24px 8px 40px;
    }

    #WMCardList,
    .track-drawer,
    .Slip{
        padding: 0 8px;
    }
    .TabletBaseContent {
        padding: 0 24px;
    }

    .dr_container {
        padding: 24px 8px 0;
    }

    .TabletNewsContainer{
        padding-left: 18%;
        width: auto !important;
    }
    .TabletNewsContainer{
        padding-left: 18%;
    }
    /* footer */
    #disclaimerRoot div {
        margin: 0 24px !important;
    }
}
