.Base {
  background: var(--jc-neutrals-white, #fff);
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 16px;
  z-index: 1;
  color: #0A2240;
}
.Header {
  margin: 8px 16px 12px 16px;
  display: flex;
  align-items: center;
  flex: 1;
}

.Title {
  color: #0A2240;
  /* JC/Title/Title 1 - Bold */
  
  font-size: 22px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 127.778% */
}
.More {
  color: #836B55;
  text-align: right;

  /* JC/Label/Label 2 - Medium */
  
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 15.6px */
}

.GridTitle {
  color: #84909F;
  text-align: left;
  /* JC/Label/Label 3 - Regular */
  
  font-size: 14px !important;;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.011px;
}
.FormCardTitle {
  
  font-size: 18px;
  font-weight: 700 !important;
  text-align: left;
}
.RaceText {
  font-size: 14px;
  font-weight: 400;
  /* font-weight: 700; */
  color: #836B55;
}
.PlacingContent {
  min-height: 60px;
  min-width: 60px;
  /* background: linear-gradient(17.63deg, #9F8B79 4.13%, #B89F8A 53.53%, #CDB097 93.94%); */
  background: #123766;
  color: #fff;
  border-radius: 8px;
  /* font-weight: 700; */
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}
.PlacingContent p {
  margin-top: 10px;
  font-size: 14px;
}
.PlacingContent span {
  font-size: 22px;
}
.Card {
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 8px 8px 14px 8px;
  /* width: 100%; */
  height: 64px;
  background-size: contain;
}
.GridContent {
  text-align: left;
  /* JC/Label/Label 1 - Bold */
  
  font-size: 14px !important;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 120%; /* 19.2px */
  line-height: 30px !important;
  letter-spacing: 0.1px;
}

.TopContainer {
  display: flex;
  padding: 16px 16px 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 12px 0px #617ca833;
  text-align: start;
}
.drawerContentNoData {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 100%;
}
.drawerContentWrapper {
  display: flex;
  padding: 16px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 2px 12px 0px #617ca833;
}
.drawerContentContainer {
  padding: 0 16px;
}
.bottomItem {
  display: flex;
  justify-content: right;
}
.black {
  color: #84909F;
}
