.header{
    height: 48px;
    color: #FFF;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    padding-bottom: 12px;
    background: linear-gradient(86.25deg, #0A2240 60.07%, #2265B4 100%),
    radial-gradient(105.33% 1607.26% at -1.73% 89.58%, #0A2240 0%, rgba(17, 44, 77, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(105.73% 1613.36% at 104.4% 11.98%, rgba(10, 34, 64, 0.1) 0%, rgba(0, 0, 0, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    
}

.header .backButton{
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    padding: 23px 16px 14px;
}
.header .rightButton{
    position: absolute;
    right: 10px;
}
.Base{
    /* margin-bottom: 1rem; */
    z-index: 1;
    background: linear-gradient(86.25deg, #0A2240 60.07%, #2265B4 100%),
    radial-gradient(105.33% 1607.26% at -1.73% 89.58%, #0A2240 0%, rgba(17, 44, 77, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(105.73% 1613.36% at 104.4% 11.98%, rgba(10, 34, 64, 0.1) 0%, rgba(0, 0, 0, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    
}
