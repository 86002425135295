.indexExplain-drawer {
  /* height: calc(100% - 80px); */
  border-radius: 12px 12px 0 0;
  color: #0A2240;
}
.indexDrawerPla .MuiPaper-root {
  border-radius: 12px 12px 0 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.indexExplain-header {
  height: 48px;
  text-align: center;
  line-height: 48px;
  font-size: 22px;
  font-weight: 700;
  position: sticky;
  top: 0;
  background: #fff;
}
.indexExplain-header img {
  position: absolute;
  right: 0;
  top: 0;
  padding: 17px;
}
.indexItem {
  display: flex;
  flex-direction: column;
}
.indexItem-title {
  text-align: center;
  background: #9DA6B2;
  font-size: 16px;
  font-weight: 400;
  border-top: 1px solid #cccccc;
}
.indexItem-title:first-child {
  border-top: none;
}
.indexItem-content-pla {
  display: flex;
}
.indexItem-content-pla:nth-child(n) {
  border-bottom: 1px solid #cccccc;
}
.indexItem-content-pla:last-child {
  border-bottom: none;
}
.indexItem-content-pla-left {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F2F3F5;
  min-width: 90px;
  min-height: 33px;
  text-align: center;
  height: inherit;
  border-right: 1px solid #cccccc;
  color: #0A2240;
}
.indexItem-content-pla-right {
  align-self: center;
  flex: 1;
  padding: 4px 8px;
}
