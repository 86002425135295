.season-tab-container {
  background-color: white;
  padding: 16px;
  border-radius: 28px 28px 0 0;
}
.season-tab-content {
  position: relative;
  display: flex;
  padding: 2px;
  border-radius: 28px;
  background-color: #e2edf6;
}
.season-tab-inner {
  z-index: 2;
  width: 50%;
  border-radius: 28px;
  padding: 8px 0;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  transition: color 0.4s ease;
}
.season-select-inner {
  z-index: 1;
  width: 49%;
  height: calc(100% - 4px);
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 28px;
  background-color: rgb(27, 59, 140);
  transition: left 0.3s ease;
}
.is-selected {
  color: white;
}
.not-selected {
  color: black;
}
