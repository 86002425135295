
.RDCompare-root{
}

.RDColunm{
    font-size: 11px;
    color: #84909F;
    padding-bottom: 7px;
}

.RDTableRow{
    min-height: 30px;
    max-height: 30px;
    align-items: center;
    font-size: 11px;
    /* font-weight: 700; */
    margin: 3px 0;
    word-break: break-word;
}
.RDTableRow-HorseName{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.CB-mask{
    display: flex;
    width: 100%;
    height: 100%;
    /* background: #E2EDF6; */
    border-radius: 6px;
    line-height: 30px;
}
.CB-item{
    flex: 1;
    position: relative;
}
.CB-item-left{
    border-right: 1px dashed rgba(85, 85, 85, 1);
}
.CB-lineblue-left{
    border-radius: 8px 0 0 8px;
}
.CB-lineblue-right{
    border-radius: 0 8px 8px 0;
}
.CB-lineblue{
    height: 16px;
    line-height: 16px;
    width: 100%;
    background: #E2EDF6;
    margin: 7px 0;
}

.CB-dataBar-right{
    background: linear-gradient(90deg, #CB4059 0%, #FF5A81 100%);
    position: absolute;
    left: 0;
    top: 0;
    height: 16px;
    margin: 7px 0;
    border-radius: 0 8px 8px 0;
}
.CB-dataBar-left{
    background: linear-gradient(270deg, #102858 0%, #538AE0 100%);

    position: absolute;
    right: 0;
    top: 0;
    height: 16px;
    margin: 7px 0;
    border-radius: 8px 0 0 8px;
}
.CB-dataBar-leftNumber {
    position: absolute;
    right: 0;
    top: 0;
    height: 16px;
    font-size: 11px;
    color: #405CBE;
}
.CB-dataBar-rightNumber {
    position: absolute;
    left: 0;
    top: 0;
    height: 16px;
    font-size: 11px;
    color: #F0416B;
}
.CB-square-container{
    display: flex;
    font-size: 11px;
    margin-top: 16px;
    flex: 1;
}

.CB-square-Reminder{
    font-size: 11px;
    margin-top: 12px;
    text-align: left;
}
.CB-square {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin: 3px 5px 0 0;
    background: #0ABEF9;
}

.RDCompare-noData {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(247, 247, 247, 1);
    border-radius: 8px;
    padding: 0 16px;
    width: 100%;
    height: calc(100% - 45px);
    font-size: 14px;
}