.Base{
    background: var(--jc-neutrals-white, #FFF);
    width: 100%;
    height: auto;
    display: flex;
    flex: 1;   
    flex-direction: column;
    padding-bottom: 16px;
    z-index: 1;
    color: #0a2240;
}
.Header{
    margin: 8px 16px 12px 16px;
    display: flex;
    align-items: center;
    flex: 1;
}

.Title{
    color: #0A2240;
    /* JC/Title/Title 1 - Bold */
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 127.778% */
}
.More{
    color: #836B55;
    text-align: right;
    
    /* JC/Label/Label 2 - Medium */
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 15.6px */
}
.BigTitle{
    color: #0A2240;
    text-align: left;
    /* JC/Label/Label 3 - Regular */
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 13.2px */
    letter-spacing: 0.011px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.GridTitle{
    color: #84909F;
    text-align: left;
    /* JC/Label/Label 3 - Regular */
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 13.2px */
    letter-spacing: 0.011px;
    /* margin-bottom: 4px; */
}
.Card{
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 8px 8px 14px 8px;
    /* width: 100%; */
    height: 64px;
    background-size: contain;
}
.GridContent{
    color: #0A2240;
    text-align: left;
    /* JC/Label/Label 1 - Bold */
    padding-top: 7px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.1px;
}


.TopContainer{
    display: flex;
    border-radius: 8px;
    box-shadow: 0px 2px 12px 0px #617CA833;
    text-align: start;
    position: relative;
}

.sliderContainer{
    width: 100%;
    /* min-height: 213px; */
}
.winnerBadge{
    width: 100%;
    height: 7px;
    background: linear-gradient(90deg, #F7DF69 50%, #CBAB13 100%);
    position: relative;
}
.winnerBadge_img{
    position: absolute;
    right: 0;
    top: -37px;
}

.winnerBadge_noVideo{
    width: 100%;
    height: 7px;
    /* background: linear-gradient(90deg, #F7DF69 50%, #CBAB13 100%); */
    position: relative;
}
.winnerBadge_img_noVideo{
    position: absolute;
    right: 0;
    top: 10px
}
.linkToSmartV{
    background-image: url('../../img/WinningMoment/BG.svg');
    border-radius: 8px;
    color: #FFF;
    background-size: cover;
}

.linkToSmartV_text{
    color: #fff;
    text-align: left;
    /* JC/Label/Label 3 - Regular */
    
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 13.2px */
    letter-spacing: 0.011px;
}

.linkToSmartV_item{
    display: flex;
    align-items: center;
}
.linkToSmartV_item > div{
    padding-right: 3px;
}
.linkToSmartV_action{
    color: #fff;
    text-align: left;
    /* JC/Label/Label 3 - Regular */
    
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 13.2px */
    letter-spacing: 0.011px;
}

.boomsfire {
    top: -80px;
    position: absolute;
    z-index: 6;
    transform: scale3d(1.5, 1.5, 1.5);
    left: 0;
    width: 430px;
}

.bottomItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.RaceText {
    font-size: 14px;
    font-weight: 400;
    color: #836B55;
    
}
