.WMInfo {
    background: var(--jc-neutrals-white, #FFF);
    width: 100%;
    height: auto;
    display: flex;
    flex: 1;   
    flex-direction: column;
    z-index: 1;
    margin-top: 110px;
    padding-top: 16px;
    color: #0a2240;
}

.SeasonHeader{
    display: flex;
    align-items: center;
    padding: 0 16px;
    background: #fff;
    box-shadow: 0px 8px 12px 0px #0000001a;
    height: 50px;
    margin-bottom: 16px;
    font-size: 18px;
    max-width: 100%;
    position: fixed;
    top: 60px;
    z-index: 1;
}
.SeasonHeader p{
    /* font-weight: 700; */
    font-size: 14px;
}
.half-3px-border{
    display: flex;
    align-items: center;
}
.half-3px-border::after {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    top: 0;
    left: 0;
    transform-origin: 0 0;
    border-width: 3px;
    border-style: solid;
    border: 3px solid #836b55;
    transform: scale(0.5, 0.5);
    border-radius: 100px;
    box-sizing: border-box;
    pointer-events: none;
}

.SeasonFooter{
    position: fixed;
    bottom: 0;
    height: 72px;
    box-shadow: 0 -8px 10px 0 #0000001a;
    display: flex;
    background: #fff;
    max-width: 100%;
    bottom: env(safe-area-inset-bottom);
}

.SeasonFooter-btn{
    display: flex;
    align-items: center;
    margin: 10px 0;
    flex: 1 1;
    background: #fff;
    justify-content: center;
    color: #836B55;
    position: relative;
}

.SeasonFooter-btn div {
    padding-right: 5px;
}
.SeasonFooter-btn p {
    /* font-weight: 500; */
}


.WMInfo-drawer {
    /* height: calc(100% - 80px); */
    border-radius: 12px 12px 0 0;
}
.WMInfo-header{
    height: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 22px;
    font-weight: 700;
    position: sticky;
    top:0;
    background: #fff;
    border-bottom: 1px solid #ccc;
    color: #0A2240;
}
.WMInfo-header img{
    position: absolute;
    right: 0;
    top: 0;
    padding: 17px;
}

.WMInfoDrawer .MuiPaper-root{
    border-radius: 12px 12px 0 0;
    padding-bottom: env(safe-area-inset-bottom);;
}

.WMInfoDrawer-list{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.WMInfo-btn{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #836B55;
    margin: 10px 16px;
    color: #fff;
    border-radius: 40px;
}

.WMCardList{
    height: calc(100vh - 61px - 60px - 66px - 12px - env(safe-area-inset-bottom));
    overflow-x: hidden;
    overflow-y: scroll;
}

#WM_HeaderICon{
    color: #0A2240;
}