.Base{
    display:flex;
    position:fixed;
    height:100vh;
    width:100%;
    z-index: 999;
    bottom:0;
    flex: 1;
 
}
.FakeBase{
    display:flex;
    position:fixed;
   
    width:100%;
    background-color:white;
    z-index: 999;
    border-radius: 24px 24px 0px 0px;
    bottom:0;
    flex: 1;
}
.FakeBaseFull{
    /* display:flex; */
    position:fixed;
   
    width:100%;
    background-color:white;
    z-index: 999;
    border-radius: 24px 24px 0px 0px;
    bottom:0;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    top:60px
}
.Container{
    flex: 1;
 
}
.Header{
    color: var(--jc-neutrals-black, #000);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* JC/Title/Title 1 - Bold */
    padding: 12px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 127.778% */
    position: sticky;
    background: #fff;
    z-index: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 12px 0px #0000001a;
    border-radius: 24px 24px 0px 0px;
}
.CloseButton{
   margin-top: 0.125em;
    position: absolute;
    right: 0;
    margin-right: 1em;
}
.MainContent{
    flex:1;
    display: flex;
    flex-direction: column;
    padding: 0 12px 0 12px;
}
.ShowButton{

    justify-content: center;
    align-items: center;
    background: var(--jc-neutrals-light-4, #F7F7F7);
    align-self: center;
    width: 100%;
    height: 24px;
    display: flex;
    margin: 2em 0;
}
.ShowTitle{
    color: var(--jc-neutrals-black, #000);
    text-align: center;
    
    /* JC/Label/Label 2 - Medium */
    
    font-size: 13px;
    font-style: normal;
    font-weight: 500;

    letter-spacing: 0.1px;
}
.AvatarContainer{
  
}
.Avatar{
    width: 90px;
    height: 90px;
    border: 2px solid rgba(255, 255, 255);
    box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.10);
}
.NameContainer{
    padding-left: 12px;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}
.NameText{
    color: var(--jc-neutrals-black, #000);
 
}
.AgeText{
    color: var(--jc-neutrals-black, #000);
    font-size: 16px;
  
}
.ProfileContent{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: start;
    /* margin-top: 2em; */
}
.SubText{
    color: var(--jc-neutrals-black, #000);
    letter-spacing: 0.011px;
    margin-top: 8px;
}
.content{
    color: var(--jc-neutrals-white, #000);
    /* JC/Number/Number 2 - Bold */
    text-align: left;
}
.title{
    color: #8B8B8B;
    /* JC/Label/Label 3 - Medium */
    text-align: left;
}
.TrophiesContainer{
    border-radius: 8px;
    background: rgba(226, 237, 246, 1);
    display: flex;
    width: 100%;
}
.Trophies{
    padding: 2px 16px 8px 16px;
    align-items: center;
    gap: 4px;
}
.Wins{
    padding: 8px 16px 2px 16px;
    align-items: center;
    gap: 4px;
}
.TrophyDesText{
    color: var(--jc-neutrals-black, #000);
}
.TrophyText{
    color: var(--jc-neutrals-white, #000);
}
.Gold{
    border-radius: 21px;
    background: #FEC107;
    width: 19px;
    height: 3px;
    flex-shrink: 0;
}
.Sliver{
    border-radius: 21px;
    background: #C9CACB;
    width: 19px;
    height: 3px;
}
.Bronze{
    border-radius: 21px;
    background: #B26E2F;
    width: 19px;
    height: 3px;
}
.Blue{
    border-radius: 21px;
    background: #1141EC;
    width: 19px;
    height: 3px;
}
.ExtraContent{
    width: 100%;
    
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.ExtraContentContainer{
    margin-top: 2em;
    margin-bottom: 2em;
    text-align: left;
    flex: 1;
    width: 100%;
}
.ExtraContentText{
    margin-top: 1em;
    color: var(--jc-neutrals-black, #000);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis,

}
.moreLink{
 
    color: blue
}
