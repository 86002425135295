.Qmark-title {
    font-size: 18px;
    font-weight: 500;
   
}
.Qmark-content {
    font-size: 16px;
    padding-top: 8px;
    line-height: 23px;
    text-align: left;
    white-space: pre-line;
}
.Qmark-footer {
    padding-top: 20px;
    font-size: 16px;
    text-align: left;
}
.Qmark-button {
    margin-top: 20px;
    color: #fff;
    background: #836B55;
    line-height: 40px;
    border-radius: 20px;
}

.Qmark-Dialog .MuiDialog-paper {
    border-radius: 20px;
    color: #424851;
}
.Qmark-Dialog .MuiBackdrop-root {
    
}