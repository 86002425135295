@media screen and (pointer: coarse) {
    html {
      overscroll-behavior-y: none;
    }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  height: 100%;

  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: constant(safe-area-inset-top); /* Fallback for older browsers */
  padding-top: env(safe-area-inset-top);
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
}
@supports(padding-top:env(safe-area-inset-top)) or  (padding-top:constant(safe-area-inset-top)) {
  .App {
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  display: none;
}


.fontSize11{
  font-size: 11px;
}
.fontSize13{
  font-size: 13px;
}
.fontSize14{
  font-size: 14px;
}
.fontSize16{
  font-size: 16px;
}
.fontSize18{
  font-size: 18px;
}
.fontSize22{
  font-size: 22px;
}
.fontWeight700{
  font-weight: 700;
}
.lineHeight17 {
  line-height: 17px;
}
.lineHeight20 {
  line-height: 20px;
}
.lineHeight23 {
  line-height: 23px;
}
.lineHeight26 {
  line-height: 26px;
}
.graycolor {
  color: #84909F
}