.sectional-tab-content {
  width: 100%;
  position: relative;
  display: flex;
  padding: 4px;
  border-radius: 4px;
  background-color: #FFFFFF;
  height: 36px;
}
.sectional-tab-inner {
  line-height: 19px; 
  z-index: 2;
  width: 50%;
  border-radius: 28px;
  padding: 6px 0;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  transition: color 0.4s ease;
}
.sectional-select-inner {
  z-index: 1;
  width: 49%;
  height: 3px;
  position: absolute;
  bottom: 2px;
  left: 2px;
  border-radius: 4px;
  background-color: #836B55;
  transition: left 0.3s ease;
}
.is-selected {
  color: #836B55;
}
.not-selected {
  color: #84909F;
}
