.season-cell {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #F2F3F5;
  padding: 8px;
}
.season-empty-cell {
  position: sticky;
  background-color: white;
  left: 0;
  z-index: 2;
  padding: 12px 8px;
  font-size: 16px;
  border-right: none !important;
  color: #0A2240;
  font-weight: 400;
}
.label-season-cell {
  min-width: 136px;
}
/* .label-season-cell::after {
  content: "";
  position: absolute;
  top: 0;
  right: -1px;
  height: 100%;
  width: 1px;
  background-color: white;
} */
.label-season-cell::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  border-right: 1px solid #ccc;
  z-index: -1;
  background-color: #ccc;
  height: 100%;
}
.season-body-cell {
  word-break: break-word;
  min-width: 120px;
  max-width: 144px;
}
.season-body-cell span {

}
.season-body-cell p {
  margin-top: 4px;

}
.season-body-cell::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  width: 7px;
  box-shadow: 5px 0px 5px #ccc;
  background-color: transparent;
}
.no-border-right {
  color: #424851;
  border-right: none !important;
}
.no-border-bottom {
  border-right: none !important;
}
.cell-shadow {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0) 40%
  );
}
.cell-label-border {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 10%
  );
}
.season-title-cell {
  padding: 4px 8px;
  background-color: #EDE7E1;
}
.cell-shadow-label {
  padding: 0 !important;
  position: sticky;
  left: 0;
  z-index: 1;
}
.label-cell {
  min-width: 60px;
  background-color: #6C7280;
  line-height: normal !important;
  padding: 12px 8px;
  color: white;
  text-align: center;
  border-right: 1px solid #CED3D9;
  font-weight: 700;
}
.content-cell {
  line-height: normal !important;
  padding: 4px 8px;

  text-align: center;
  border-right: 1px solid #CED3D9;
}
.table-container {
  box-shadow: none;
  height: 100%;
  overflow: auto;
  border-radius: 0;
  -webkit-overflow-scrolling: touch;
}
.table-container table{
  position: relative;
  height: max-content;
  border-collapse: separate;
  padding-bottom: 20px;
}
#FR_form_labelHead {
  position: sticky;
    top: 0;
    z-index: 99;
    height: 64px;
}
.content-cell:not(.season-cell) {
  color: #424851;
  max-width: 100px;
  word-wrap: break-word;
}
.video-wapper {
  display: flex;
  justify-content: center;
}
.video-wapper img:nth-last-of-type(1) {
  margin-left: 4px;
}