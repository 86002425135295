.race-Header {
  margin: 8px 16px 12px 16px;
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 22px;
}

.race-Title {
  color: #0A2240;
  /* JC/Title/Title 1 - Bold */
  
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 127.778% */
}
.race-season-cell {
  position: sticky !important;
  left: 0;
  z-index: 2;
  background-color: #F2F3F5;
  padding: 8px;
}
.race-season-empty-cell {
  position: sticky;
  background-color: white;
  left: 0;
  z-index: 2;
  padding: 12px 8px;
  font-size: 16px;
  border-right: none !important;
  color: #0A2240;
  font-weight: 400;
}
.race-label-season-cell {
  min-width: 100px;
}
.race-label-season-cell::after {
  content: "";
  position: absolute;
  top: 0;
  right: -1px;
  height: 100%;
  width: 1px;
  background-color: white;
}
.race-season-body-cell {
  word-break: break-word;
  min-width: 120px;
  max-width: 144px;
}
.race-season-body-cell p {
  min-width: 28px;
  font-size: 14px;
}
.race-season-body-p {
  margin-right: 4px;
  /* text-align: right; */
}
.race-season-body-cell::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  width: 7px;
  box-shadow: 5px 0px 5px #ccc;
  background-color: transparent;
}
.race-no-border-right {
  color: #424851;
  border-right: none !important;
}
.race-no-border-bottom {
  border-right: none !important;
}
.race-cell-shadow {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0) 40%
  );
}
.race-cell-label-border {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 10%
  );
}
.race-season-title-cell {
  padding: 4px 8px;
  background-color: #EDE7E1;
}
.race-cell-shadow-label {
  padding: 0 !important;
  position: sticky;
  left: 0;
  z-index: 1;
}
.race-label-cell {
  min-width: 60px;
  background-color: #6C7280;
  line-height: normal !important;
  padding: 12px 8px;
  color: white;
  font-size: 14px;
  text-align: center;
  border-right: 1px solid #CED3D9;
  font-weight: 700;
}
.race-content-cell {
  line-height: normal !important;
  padding: 4px 8px;
  font-size: 14px;
  text-align: center;
  border-right: 1px solid #CED3D9;
  color: #424851;
}
.race-table-container {
  box-shadow: none;
  border-radius: 0;
}
.race-css-1ex1afd-MuiTableCell-root:not(.season-cell) {
  white-space: nowrap;
}
.race-video-wapper {
  display: flex;
  justify-content: center;
}
.race-video-wapper img:nth-last-of-type(1) {
  margin-left: 4px;
}
#RD_FR_form_labelHead{
  height: 64px;
}