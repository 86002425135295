.news-card-container {
  padding: 0 17.5px 17px 17.5px;;
  min-width: calc(100% - 35px);
}
.card-just-one {
  padding: 0 12px !important;
  min-width: calc(100% - 24px) !important;
}
.news-content-wrapper {
  min-height: 98px;
  position: relative;
  z-index: 9;
  display: flex;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.2);
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15); */
  flex: 1;
}
.news-content-wrapper-noti {
  min-height: 98px;
  position: relative;
  z-index: 9;
  display: flex;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  background-color: #fff;
  flex:1
}
.owner-ch-min-height {
  min-height: 106.19px !important;
}
.news-content-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 8px;
  justify-content: space-between;
}
.news-content-wrapper img:nth-of-type(1) {
  object-fit: contain;
  min-width: 36px;
  min-height: 36px;
  align-self: flex-start;
  padding-top: 8px;
}
.news-content-wrapper-noti img:nth-of-type(1) {
  object-fit: contain;
  min-width: 36px;
  min-height: 36px;
  align-self: flex-start;
  padding-top: 8px;
}
.news-content {
  color: #ffffff;
  font-size: 14px;
  text-align: left;
  margin-left: 8px;
}
.news-content-noti {
  color: #0A2240;
  font-size: 14px;
  text-align: left;
  margin-left: 8px;
}

.news-content p {
  margin-top: 8px;
  line-height: 21px;
}
.owner-news-type {
  position: absolute;
  bottom: 8px;
  right: 12px;
  
}
.news-type-text-noti {
  margin-top: 8px;
  color: #836B55;
  line-height: 19.2px;
  text-align: right;
  font-size: 14px;
}
.news-type-text {
  margin-top: 8px;
  color: #DBCFC3;
  line-height: 19.2px;
  text-align: right;
  font-size: 14px;
}
.show-more {
  position: absolute;
  right: 13px;
  top: 13px;
}
.card-bottom
{
  /* margin-bottom: 15px; */
}

.news-card-no-message {
  min-height: auto;
  /* font-weight: bold; */
  font-size: 14px;
  background: #fff;
}

@media screen and (min-width: 768px) {
  .news-card-container {
    padding: 17px 17.5px 0px 17.5px;
    min-width: calc(100% - 18%) !important;
  }
}