.Base{ 
    background: var(--jc-neutrals-white, #FFF);
    border-radius: 24px 24px 0px 0px;
    width: 100%;
    height: auto;
    display: flex;
    flex: 1;
    z-index:1;
    text-align: left;
    color: #0a2240;
}
.Content{
    flex: 1;
    display: flex;
    margin-top: 24px;
    padding: 0 16px 20px 16px;
    flex-direction: column;
}
.Header{
    /* JC/Title/Title 1 - Bold */
    /* padding-bottom: 12px; */
    font-weight: 700;
    font-size: 22px
}
.Gold{
    border-radius: 21px;
    background: #FEC107;
    width: 19px;
    height: 3px;
    flex-shrink: 0;
}
.Sliver{
    border-radius: 21px;
    background: #C9CACB;
    width: 19px;
    height: 3px;
}
.Bronze{
    border-radius: 21px;
    background: #B26E2F;
    width: 19px;
    height: 3px;
}
.Blue{
    border-radius: 21px;
    background: #1141EC;
    width: 19px;
    height: 3px;
}
.AvatarContainer{
    fill: #FAF9F9;
    filter: drop-shadow(0px 4px 8px rgba(1, 13, 57, 0.15));
    flex: 1;

}
.Avatar{
    max-width: 80%;
    max-height: 80%;

}

.GridTitle{
    color: #84909F;
    font-size: 14px;
    text-align: left;
    /* JC/Label/Label 3 - Regular */
    letter-spacing: 0.011px;
    margin-bottom: 4px;
}
.GridValue{
    color: #0A2240;
    font-size: 14px;
}

.Card{
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 8px 8px 14px 8px;
    /* width: 100%; */
    height: 64px;
    background-size: contain;
}
.GridContent{
    color: var(--jc-neutrals-black, #000);
    text-align: left;
    /* JC/Label/Label 1 - Bold */
    
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.1px;
}
.ShowButton{

    justify-content: center;
    align-items: center;
    background: var(--jc-neutrals-light-4, #F7F7F7);
    align-self: center;
    width: calc(100% - 32px);
    height: 24px;
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
}
.ShowTitle{
    color: var(--jc-neutrals-black, #000);
    text-align: center;
    
    /* JC/Label/Label 2 - Medium */
    
    font-size: 13px;
    font-style: normal;
    font-weight: 500;

    letter-spacing: 0.1px;
}
.InfoIcon {
    top: 0.125em;
    margin-left: 0.125em;
    position: relative;
  }

@media only screen and (max-width: 320px){

}