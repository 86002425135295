.Base{
    background: var(--jc-neutrals-white, #FFF);
    width: 100%;
    height: auto;
    display: flex;
    flex: 1;   
    flex-direction: column;
    padding-bottom: 20px;
    z-index: 1;
    color: #0A2240;
}
.Header{
    margin: 8px 16px 12px 16px;
    display: flex;
    align-items: center;
    flex: 1;
}

.Title{

    /* JC/Title/Title 1 - Bold */
    
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 127.778% */
}
.SubTitle{
    /* color: var(--jc-neutrals-black, #000); */

    /* JC/Label/Label 1 - Bold */
}
.More{
    color: #836B55;
    text-align: right;
    
    /* JC/Label/Label 2 - Medium */
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 15.6px */
}
.Tip{
    align-items: flex-start;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-right: 16px;
    padding: 8px 16px;
    box-shadow: 0 2px 12px 0 #617ca833;
}
.TipRow{
    display: flex;
    padding: 8px 16px;
    border-radius: 8px;
    background: var(--jc-neutrals-white, #FFF);
    border-radius: 8px;
    box-shadow: 0px 2px 12px 0px #617CA833;
    text-align: start;
    
}
.PassedBox{
    background:rgb(61, 172, 85);
    max-width: 51px;
    width: 51px;
    height: 49px;
    max-height: 49px;
    min-width: 52px;
    min-height: 49px;
    border-radius: 8px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

}
.Date{
    color: rgba(85, 85, 85, 1);
    /* JC/Label/Label 3 - Medium */
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 13.2px */
    text-align: start;
}
.DateText{
    color: #84909F;
}