.trackwork{
    background: white;
    height: 100%;
    /* border-radius: 24px 24px 0 0; */
    font-size: 16px;
    z-index: 1;
    color: #0A2240;
}

.trackwork-calender{
  
}
.selecedCircleColrt{
    background: #EDE7E1;
}

.disabledDay{
    background: #F2F3F5;
}
.calc-height{
    min-height: calc(18px);
    max-height: calc(18px);
}
.calc-bottom {
    bottom: calc(18px - 16px);
}
.disabledBG-mask{
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #F2F3F5;
    opacity: 0.8;
    z-index: 1;
}
.displayNone {
    display: none;
}
.pickersDayRoot{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-right: 1px solid #CED3D9;
}
.pickersDayRoot-taglist{
    text-align: left;
    padding: 0 3px;
    width: -webkit-fill-available;
    flex: 1;
    overflow: hidden;         
    -webkit-tap-highlight-color: transparent;      
}
.pickersDayRoot-taglist img{
    display: block;
    margin-bottom: 4px;
}

.pickersDayRoot-taglistItem{
    font-size: 14px;
    padding-bottom: 2px;
    font-weight: 400;
}
.pickersDayRoot-taglistItem div span::before{
    content: '';
    padding-right: 2px;
    margin-right: 2px;
}
.pickersDayRoot-taglistItem .B_0 span::before{
    background: #883A95
}
.pickersDayRoot-taglistItem .T_0 span::before{
    background: #4D9B1D
}
.pickersDayRoot-taglistItem .G_0 span::before{
    background: #E99A00
}
.pickersDayRoot-taglistItem .S_0 span::before{
    background: #1E7DA6
}
.pickersDayRoot-taglistItem .R_0 span::before{
    background: #D62D2D
}
.pickersDayRoot-taglistItem .ET_0 span::before{
    background: #828D56 
}
.pickersDayRoot-taglistItem .AW_0 span::before{
    background: #10B8C3
}

.taglistItem-HasReplay{
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
}
.taglistItem-HasReplay svg{
    width: 11px ;
    height: 11px;
    padding-right: 2px;
}
.pickersDayRoot-taglistItem .B_1{
    background: #883A95
}
.pickersDayRoot-taglistItem .T_1{
    background: #4D9B1D
}
.pickersDayRoot-taglistItem .G_1{
    background: #E99A00
}
.pickersDayRoot-taglistItem .S_1{
    background: #1E7DA6
}
.pickersDayRoot-taglistItem .R_1{
    background: #D62D2D
}
.pickersDayRoot-taglistItem .ET_1{
    background: #828D56 
}
.pickersDayRoot-taglistItem .AW_1{
    background: #10B8C3
}

.dayContainer{
    position: relative;
    height: 20px;
    /* width: 24px; */
    font-family: none;
    color: #0A2240;
}

/* .dayContainer::before{
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #1976d2;
    position: absolute;
    top: 6px;
    left: 10px;
} */

/* ----------------------- */
/* ----tagscontainer------ */
/* ----------------------- */
.tagscontainer{
    border-top: 1px solid #8B8B8B;
    display: flex;
    padding: 10px 0 10px 10px;
    font-size: 11px;
    min-height: 74px;
}
.tagscontainer-tag{
    display: flex;
    /* flex-direction: column; */
    align-content: flex-start;
    /* margin-right: 10px; */
}

.tagscontainer-tag >div{
    display: flex;
    /* padding-right: 5px; */
    padding-bottom: 5px;
}
.tagscontainer-tag >div:first-child{
    /* padding-bottom: 6px; */
    display: flex;
}
.tagscontainer-tag div span{
    font-weight: 700;
}
.tagscontainer-tag div span::before{
    content: '';
    padding-right: 2px;
    margin-right: 2px;
}
.tagscontainer-tag #tagsctB::before{
    background: #883A95
}
.tagscontainer-tag #tagsctT::before{
    background: #4D9B1D
}
.tagscontainer-tag #tagsctG::before{
    background: #E99A00
}
.tagscontainer-tag #tagsctS::before{
    background: #1E7DA6
}
.tagscontainer-tag #tagsctET::before{
    background: #828D56
}
.tagscontainer-tag #tagsctAW::before{
    background: #10B8C3
}
.tagscontainer > div:last-child{
    /* margin-left: auto; */
    /* align-self: center; */
}
/* ----------------------- */
/* ----------------------- */
/* ----------------------- */



/* overwrite mui css */
.indexDrawer .MuiPaper-root{
    border-radius: 12px 12px 0 0;
    top: 60px;
}

.pickersDayRoot .Mui-selected{
    height: 16px;
    width: 16px;
    font-size: 12px;
    background-color: #836B55 !important;
}

.trackwork .MuiDateCalendar-root {
    width: 100%;
    margin: 0;
    max-height:none;
    height: 100%;
}
.pickersDayRoot .MuiButtonBase-root{
    transition:none;
    -webkit-transition: none;
}
.pickersDayRoot .MuiButtonBase-root:not(.Mui-selected){
    border: none;
    height: 24px;
    width: 24px;
    font-size: 11px;
    color: #0A2240;
}

.trackwork .MuiDayCalendar-header{
    justify-content: space-evenly;
    border-bottom: 1px solid #8B8B8B;
}
.trackwork .MuiDayCalendar-header span{
    flex: 1;
    color: #0A2240;
    font-size: 16px;
    font-weight: 500;
}
.trackwork .MuiDayCalendar-weekContainer{
    justify-content: space-evenly;
    min-height: 88px;
    border-bottom: 1px solid #CED3D9;
    margin: 0;
    flex: 1;
}
.trackwork .MuiDayCalendar-weekContainer:last-child{
    border-bottom: none;
}
.trackwork .MuiDayCalendar-weekContainer .pickersDayRoot:last-child{
    border-right: none;
}

.trackwork .MuiPickersCalendarHeader-labelContainer{
    justify-content: center;
}
.trackwork .MuiPickersCalendarHeader-root{
    position: relative;
    justify-content: center;
}

.trackwork .MuiPickersArrowSwitcher-spacer{
    width: 0;
}
.trackwork .MuiPickersCalendarHeader-labelContainer{
    justify-content: center;
    margin-right: unset
}
.trackwork .MuiPickersArrowSwitcher-root{
    align-items: unset
}

.trackwork .MuiPickersArrowSwitcher-root >button:first-child {
    position: absolute;
    left: 0;
    top: -5px;
    font-size: 24px;
}
.trackwork .MuiPickersArrowSwitcher-root >button:last-child{
    position: absolute;
    right: 0;
    top: -5px;
    font-size: 24px;
}
.trackwork .MuiPickersArrowSwitcher-root button {
    color: #0A2240
}
.trackwork .MuiPickersArrowSwitcher-root button[disabled] {
    color: #84909F
}
.trackwork .MuiDayCalendar-monthContainer{
    position: relative;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: overlay;
}

.trackwork .MuiPickersCalendarHeader-label {
    font-size: 22px;
    font-weight: 700;
}

.trackwork .MuiDayCalendar-slideTransition {
    height: calc(75vh - 203px) !important;
    min-height: 125px;
}
.trackwork .MuiPickersDay-root.Mui-selected:hover{
    background-color: #836B55;
}

/* .trackwork .MuiDayCalendar-monthContainer::-webkit-scrollbar{
    background-color: transparent;
    width: 5px;
}
.trackwork .MuiDayCalendar-monthContainer::-webkit-scrollbar-thumb{
    background-color: transparent;
    border-radius: 1rem;
    background-clip: content-box;
}
.trackwork .MuiDayCalendar-monthContainer[scroll]::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover{
    background-color: rgba(50,50,50,.3);
} */