.CommentsOnRunning-root{
    background: unset;
    /* color: #fff; */
    width: 100%;
}
.CommentsOnRunning-Header {
    margin: 8px 0 12px 0;
    text-align: left;
    /* display: flex; */
    /* align-items: center; */
    /* flex: 1; */
}
.CommentsOnRunning-Title {
    color: #0a2240;
    /* JC/Title/Title 1 - Bold */

    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 127.778% */
}
.CommentsOnRunning-TopContainer{
    color:#0a2240;
    display: flex;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 2px 12px 0px #617ca833;
    text-align: start;
    display: block;
}
.ctent-header{

}
.ctent-text{
    line-height: 20px;
    padding: 8px 0;
    word-break: break-word;
}
.ctent-disclaimer{
    line-height: 15px;
}