.statContainer{
    margin-top: 24px;
    color: var(--jc-neutrals-white, #FFF);
    text-align: left;
    padding: 0 16px
}
.content{
  
}
.numberItem{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.title{
    color: #FFF;
    /* JC/Label/Label 3 - Medium */
    
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 13.2px */
    text-align: left;
}
.Trophies{
    border-radius: 8px;
    background:#3b4f67;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    width: 100%;
    padding: 8px 16px;
    align-items: center;
    gap: 4px;
    
}
.TrophyDesText{
    color: var(--jc-neutrals-white, #FFF);

    /* JC/Label/Label 3 - Medium */
    
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 13.2px */   
}
.TrophyText{
    color: var(--jc-neutrals-white, #FFF);

    /* JC/Number/Number 2 - Bold */
    
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
}
.Gold{
    border-radius: 21px;
    background: #FEC107;
    width: 19px;
    height: 3px;
    flex-shrink: 0;
}
.Sliver{
    border-radius: 21px;
    background: #C9CACB;
    width: 19px;
    height: 3px;
}
.Bronze{
    border-radius: 21px;
    background: #B26E2F;
    width: 19px;
    height: 3px;
}
.Blue{
    border-radius: 21px;
    background: #1141EC;
    width: 19px;
    height: 3px;
}
.Remarks{
    color: var(--jc-neutrals-white, #FFF);
    /* JC/Body/Body 3 - Regular */
    
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 127.273% */
    text-align: start;
}

@media only screen and (max-width: 320px){
    .title{
        font-size: 9px;
    }
    .content{
        font-size: 14px;
    }
    .TrophyText{
        font-size: 12px;
    }
    .Gold{
        border-radius: 21px;
        background: #FEC107;
        width: 14px;
        height: 3px;
        flex-shrink: 0;
    }
    .Sliver{
        border-radius: 21px;
        background: #C9CACB;
        width: 14px;
        height: 3px;
    }
    .Bronze{
        border-radius: 21px;
        background: #B26E2F;
        width: 14px;
        height: 3px;
    }
    .Blue{
        border-radius: 21px;
        background: #1141EC;
        width: 14px;
        height: 3px;
    }
    .Trophies{
        gap: 10px;
    }
    .TrophyDesText{
        font-size: 11px;
    }
}
