.Base{
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    color: #0A2240;
    /* margin-bottom: 24px; */
}
.TicketsContainer{
    display: flex;
    flex: 1;

    flex-direction: column;
}
.TicketBase{
    display: flex;
   
    flex: 1 0 0;

    border-radius: 8px;
    box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.05),0px 4px 12px 0px rgba(0, 0, 0, 0.05),4px 0px 12px 0px rgba(0, 0, 0, 0.05);
    margin-top: 6px;
    margin-bottom: 6px;
}
.TicketColor{
    display: flex;
    width: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
    background: #529C1B;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.Divider{
  
}
.Title{
    /* JC/Title/Title 1 - Bold */
    
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 127.778% */
    text-align: left;
}
.RaceSection{
    display: flex;
    flex-direction: column;
    padding: 12px
}
.Race{
    display: flex;
    width: 60px;
    height: 60px;
    padding: 2px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #EEF0F3;
    color: #0a2240;
    margin-top: 4px;
    margin-bottom: 4px;
}
.RaceText{

    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 15.6px */
    letter-spacing: 0.1px;
    
}
.RaceNum{
    color: #0a2240;
    text-align: center;
    
    /* JC/Number/Number 1 - Bold */
    
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 25.2px */
}
.RaceTimeText{
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 15.6px */
    letter-spacing: 0.1px;
    
}
.RaceTime{
    display: flex;
    width: 60px;
    height: 60px;
    padding: 2px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;
}
.RaceDetail{
    flex: 1;
    align-self: stretch;
    padding: 16px 12px 12px 0px;
}
.ClassDetail{
    text-align: left;
    position: relative;
    /* margin-bottom: 12px; */
    gap:4px;
    display: flex;
    flex-direction: column;
}
.LocationText{
    color: #0a2240;
    /* JC/Label/Label 2 - Medium */
    
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 15.6px */
    letter-spacing: 0.1px;
}
.shareBtn{
    position: absolute;
    right:3px;
}
.RaceCardShare{
    text-align: right;
    color: var(--jc-primary-banding-blue, #836B55);    /* JC/Label/Label 2 - Medium */
    
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 15.6px */
    letter-spacing: 0.1px;
    margin-left: auto;
    padding: 0 0 12px 12px;
}
.JockeySection{
    margin-top: 12px;
    flex:1
}
.Jockey{
    display: flex;
    align-items: center;
    flex:1
}
.LabelSection{
    display: flex;
    flex-direction: column;
    margin-left: 4px;
    text-align: left;
}
.Label{
    color: var(--jc-neutrals-dark-2, #555);

    /* JC/Label/Label 3 - Medium */
    
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 13.2px */
}
.Name{
    color: var(--jc-neutrals-black, var(--color-neutrals-black, #000));

    /* JC/Label/Label 1 - Bold */
    
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.1px;
}
.BottomSection{
    display: flex;
    gap:12px
}
.Avatar{
    width: 40px;
    height: 40px;
    background-color: #BACFE3;
    align-self: flex-start;
}