.Base{
    background: var(--jc-neutrals-white, #FFF);
    width: 100%;
    height: auto;
    display: flex;
    flex: 1;   
    flex-direction: column;
    z-index: 1;
    color: #0A2240;
}
.Title{
    /* JC/Title/Title 1 - Bold */
    
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 127.778% */
}
.More{
    color: #836B55;
    text-align: right;
    
    /* JC/Label/Label 2 - Medium */
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 15.6px */
    letter-spacing: 0.1px;
}
.Header{
    margin: 8px 16px 12px 16px;
    display: flex;
    align-items: center;
    flex: 1;
}

.TableHeader{
    background: #6A737F;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px 8px 0 0;
}

.GridTitle{
    color: var(--jc-neutrals-black, #000);
    /* JC/Label/Label 2 - Medium */
    
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 15.6px */
    letter-spacing: 0.1px;
    text-align: left;
}
.TableHeaderCell{
    display: flex;
    padding: 4px 12px;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    font-size: 14px;
}
.Table{
    box-shadow: 0px 2px 12px 0px #617CA833;
    width:100%;
    border-radius:8px;
    background-color: #FFF;
    text-align: left;
    color: #0A2240;
    height: max-content;
}
.DividerHeader{
    background: #CED3D9;
    opacity:0.3;
    width:1;
}
.DividerCell{
    background: #CED3D9;
    opacity:0.3;
    width:1;
}
.DividerVer{
    background: #CED3D9;
    opacity:0.3;
    height:1;
}
/* table {
    border-collapse: separate;
    border-spacing: 0;
    min-width: 350px;
    margin: 8px 16px 12px 16px;

}
table tr th,
table tr td {
border-left: px solid rgba(255, 255, 255, 1);
border-bottom: 1px solid #bbb;
padding: 5px;
}
table tr th:first-child,
table tr td:first-child {
border-left: 1px solid rgba(255, 255, 255, 1);
}
table tr th {
background: var(--jc-secondary-product-specific-racing-light, #BACFE3);
border-top: 1px solid #bbb;
text-align: left;
}


table tr:first-child th:first-child {
border-top-left-radius: 6px;
}


table tr:first-child th:last-child {
border-top-right-radius: 6px;
}


table tr:last-child td:first-child {
border-bottom-left-radius: 6px;
}


table tr:last-child td:last-child {
border-bottom-right-radius: 6px;
} 
*/