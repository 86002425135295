.MCLloyd-root{
    background: unset;
    /* color: #fff; */
    width: 100%;
    margin: 0 16px 16px;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 #617ca833;
}

.MCLloydCollapse{
    
    color: #000;
}
.MCLloyd-sliderContainer{
    width: 100%;
    min-height: 213px;
    color: #0A2240;
}
.MCLloyd-headerTitle{
    color: #0A2240;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
}


.MCLloyd-Card{
    background: #fff;
    border-radius: 8px;
    padding-bottom: 0;
}

.MCLloyd-Item-left{
    height: 60px;
    width: 60px;
    background: #E2EDF6;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 700;
}

.MCLloyd-Item-Right{
    text-align: left;
    display: flex;
    flex-direction: column;
}
.MCLloyd-Item-Right-Title{ 
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    margin: 0;
    padding-bottom: 16px;
}

.MCLloyd-Item-Right p {
    margin-bottom: 0;
}
.MCLloyd-Item-PosTime{
    display: flex;
    align-items: center;
}

.MCLloyd-RaceDetails{
    display: flex;
    justify-content: flex-end;
    color: #836B55;
    font-size: 13px;
    
}


.MCLloyd-RaceDetails div:last-child{
    padding-left: 3px;
}

.MCLloyd-Btn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #836B55;
    font-size: 14px;
}

.MCLloyd-Btn p{
    padding-left: 5px;
}
.MCLloyd-Btn span{
    width: 16px !important;
    height: 16px !important;
}
.MCLloyd-Btn div{
    margin-left: 10px;
}

.MCLloyd-Header{
    margin: 8px 16px 12px 16px;
    display: flex;
    align-items: center;
    flex: 1;
    color: #0A2240;
}
.MCLloyd-avatar{
    object-fit: cover;
    border-radius: 50%;
    width: 32px;
    border: 2px solid rgba(255, 255, 255);
    height: 32px;
    margin-right: 10px;
}
.MCLloyd-Title{
    color: #0A2240;
    /* JC/Title/Title 1 - Bold */
    
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 127.778% */
}
.MCLloyd-flex{
    display: flex;
    align-items: center;
}
.sliderItemRoot{
    height: 100%;
}
/* overwrite UI */
.MCLloyd-sliderContainer .swiper-pagination-bullet-active{
    background: #003c84;
}
/* .MCLloyd-sliderContainer .swiper-pagination{
    bottom: var(--swiper-pagination-bottom, 0px) !important;
} */
.MCLloyd-sliderContainer .swiper-wrapper,
.MCLloyd-sliderContainer .swiper-slide{
    height: inherit !important;
   
}
.MCLloyd-sliderContainer  .swiper{
    padding: 16px 16px 36px
}
/* .MCLloyd-root {
    box-shadow: unset !important
} */