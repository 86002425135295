.drawer-control{
    position: fixed;
    bottom: calc(22vh - 16px);
    z-index: 10;
    width: 32px;
    line-height: 28px;
    height: 32px;
    background: #fff;
    border-radius: 16px;
    left: calc(50% - 16px);
    box-shadow: 0px 2px 4px 0px #C2C2C280;
}
.drawer-workout{
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 8px;
    font-size: 22px;
    font-weight: 700;
}
.drawer-workoutTitle{
    float: left;
    top: 12px;
    left: 16px;
    font-weight: 700;
    font-size: 18px;
}
.drawer-workoutIcon{
    float: right;
    top: 12px;
    right: 16px;
}

.track-drawer-isExpend{
    max-height: max-content !important;
}
.track-drawer{
    background-color: #fff;
    color: #0A2240;
    box-shadow: 0px -8px 10px 0px #0000001A;

    overflow-y: auto;

    display: flex;

    flex-direction: column;

    flex: 1 0 auto;
    z-index: 5;

    position: fixed;
    top: auto;
    outline: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: calc(22vh);
    max-height: calc(22vh);
    padding-top: constant(safe-area-inset-top); /* Fallback for older browsers */
    padding-top: env(safe-area-inset-top);
}

.drawer-container {
    display: flex;
    padding: 16px;
    overflow: hidden;
    flex: 1;
}
.drawer-container-left{
    width: 60px;
    height: 60px;
    background: #EEF0F3;
    border-radius: 8px;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.drawer-container-left > div:first-child{
    font-weight: 700;
    font-size: 18px;
}
.drawer-container-left > div:last-child{
    font-weight: 400;
    font-size: 14px !important;
}
.drawer-container-right{
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: max-content;
    min-height: -webkit-max-content;
}


.drawer-right-item{
    /* height: auto; */
    display: flex;
    padding: 6px 0;
    border-bottom: 1px solid #CED3D9;
}
.drawer-right-item:last-child {
    border-bottom:none
}

.drawer-item-line{
    width: 1px;
    margin-right: 16px;
}
.drawer-item-content{
    display: flex;
    flex-direction: column;
    text-align: left;
    flex: 1;
}
.drawer-item-content p {
    font-size: 14px;
    line-height: 20px;
}
.drawer-content-header{
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 2px;
}
.drawer-content-noData{
    font-weight: 400;
    text-align: center;
    width: 100%;
    font-size: 14px;
}
.drawer-header-left{
    display: flex;
    align-items: center;
}
.drawer-header-left span {
    font-size: 18px;
    font-weight: 700;
}
.drawer-header-left img {
    padding-right: 8px;
}