.title{
    color: #0a2240;

    /* JC/Title/Title 1 - Bold */
    
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 127.778% */
    text-align: left;
    padding: 0 16px 16px 16px;
}
.subTitle{
    color: #0a2240;

    /* JC/Title/Title 1 - Bold */
    
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 127.778% */
    text-align: left;
    padding: 0 16px 0px 16px;
}
.content{
    color: #0a2240;

    /* JC/Label/Label 3 - Regular */
    
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0.011px;
    text-align: left;
    padding: 0 16px 0px 16px;
}

.square {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin: 3px 5px 0 0;
    min-width: 10px;
}
.legendItem{
    font-size: 11px;
    display: flex;
    align-items: start;
    padding: 0 16px 0px 16px;
}
.noData{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 45px);
    background-color: rgba(247, 247, 247, 1);
    border-radius: 8px;
    /* padding: 0 16px; */
    width: 100%;
    min-height: 358px;
    font-size: 14px;
    color: #0A2240; 
}