
.owner-profile-container {
  z-index: 10;
  flex: 1;
  display: flex;
  flex-direction: column;

}
p {
  margin: 0;
}
.top-news-card, .top-news-card-multiple {
  display: flex;
  overflow-x: auto;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.top-news-card::-webkit-scrollbar {
  display: none;
}
.top-tips {
  background-color: #e2edf6;
  height: 84px;
  padding: 8px;
  border-radius: 8px;
  text-align: left;
}
.top-tips span {
  font-size: 11px;
  line-height: 14px;
}
.top-tips p {
  margin-top: 8px;
  line-height: 21px;
}
.my-horse-container {
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 0 24px;
}
.my-horse-title-line {
  margin: 4px auto 0px auto;
  width: 19px;
  height: 2px;
  text-align: center;
  background-color: rgba(205, 167, 133, 1);
}
.my-horse-title p {
  color: white;

}
.my-horse-item {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items:center
}
.item-avatar {
  object-fit: cover;
  border-radius: 50%;
  width: 80px;
  border: 2px solid rgba(255, 255, 255);
  height: 80px;
}
.my-horse-item p {
  color: white;

}

.my-horse-avatar {
  position: relative;
}
.my-horse-avatar > img:last-child{
  bottom: 0px;
  position: absolute;
  right: -10px;
  width: 32px;
}
.item-bottom-content {
  display: flex;
  flex: 1;
  align-items: end;
  justify-content: center;
  margin-top: 4px;
  word-break: break-word;
  color: #0A2240;
}
.item-bottom-content .item-icon {
  width: 16px;
  height: 16px;
  margin-left: -16px;
  align-self: flex-end;
}
/* .item-icon{
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  bottom: 1px;
  right: 9px;
} */

/* .only-one{
  right:90px !important;
} */
.my-horse-container .item-more {
  margin-top: 12px;
  width: 100%;
  border-radius: 6px;
  background-color: white;
  padding: 4px;
}
.item-more p {
  text-align: center;
  
}
.owner-profile-other-container-owner {
  margin-top: 12px;
  height: 100%;
  padding: 24px 0 40px 0;
  background-color: white;
  border-radius: 20px 20px 0 0;
  flex: 1;
}
.owner-download-form-container {
  padding: 24px 16px 0;
  color: #0A2240;
}
.download-form-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.download-form-title-wrapper p{
  text-align: left;
}
.download-form-title {
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
}
.see-all {
  font-size: 14px;
  color: #836B55;
  display: flex;
  align-items: center;
  min-width: 76px;
  justify-content: right;
}
.form-content {
  margin-top: 12px;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  box-shadow: 0px 2px 12px 0px #617ca833;
}
.form-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 14px;
}
.form-item-title {
  color: var(--jc-neutrals-black, #000);
  text-align: left;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.1px;
}
.form-download-btn {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.form-item-line {
  background-color: #CED3D9;
  height: 1px;
  margin: 8px 0 8px 0;
}

.top-news-card .news-card-container{
  padding: 12px 17.5px 0px 17.5px;
  min-width: calc(100% - 35px);
}

.top-news-card-multiple .news-card-container{
  padding: 12px 0px 0px 12px;
  min-width: calc(100% - 35px);
}

.top-news-card-multiple .news-card-container:last-child{
  padding: 12px 12px 0px 12px;
  min-width: calc(100% - 35px);
}
