/* overwrite UI css */
.sliderContainer2 .slick-dots{
    bottom: 10px !important;
}
.sliderContainer2 .slick-list{
    /* min-height: 213px !important; */
    height: 202px;
    border-radius: 8px 8px 0 0;
}

.sliderContainer2 .slick-slide img {
    width: 100%;
    height: 202px;
}

.sliderContainer2 .slick-dots li.slick-active button:before {
    color: #FFFFFF;
}

.sliderContainer2 .slick-dots li{
    margin: 0;
}

@media only screen and (max-width: 479px) {
    .WM_raceHightLIght_btn{
        /* border-left: 7px solid; */
    }
}