.Base{
    width: 100%;
    align-items: start;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    display: flex;
    margin: 16px 0;
    height: 100%;
}
.TipBase{
    border-radius: 8px;
    /* background: linear-gradient(90deg, #28647F 0.08%, #3D9BC4 99.94%, #3D9BC4 99.95%); */
    background: #4974a7;
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 24px) ;
    position: relative;
    flex-shrink: 0;
    padding: 1px 0;
    margin-left: 12px;
}
.TipBase:last-child{
    margin-right: 12px;

}
.RaceType{
    color: var(--jc-neutrals-white, #FFF);
    /* font-family: 'Roboto Condensed'; */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: 0.1px;
    word-break: break-all;
    text-transform: uppercase;
}
.item{
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}
.column{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.ShareIcon{
    text-align: end;
    padding: 3px 3px 0 0;
}
.itemStart{
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
}
.Title{
    color: var(--jc-neutrals-white, #FFF);
    text-align: left;
    
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1px;
    text-transform: uppercase;
}
.SubTitle{
    color: var(--jc-neutrals-white, #FFF);
    text-align: left;
    font-size: 14px;
}
@media only screen and (max-width: 320px){
    .RaceType{
        font-size: 12px;
    }
    .SubTitle{
        font-size: 11px;
    }
    .RaceIcon{
        width: 32px;
    }
}
