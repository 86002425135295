@keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .blinking-icon {
    position: relative;
    display: inline-block;
  }
  
  .blink {
    animation: blink 2s infinite;
  }
  
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
  }