.indexExplain-drawer {
    /* height: calc(100% - 80px); */
    border-radius: 16px 16px 0 0;
    color: #0A2240;
}
.indexExplain-header{
    height: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 22px;
    font-weight: 700;
    position: sticky;
    top:0;
    background: #fff;
    border-bottom: 1px solid #ccc;
}
.indexExplain-header img{
    position: absolute;
    right: 0;
    top: 0;
    padding: 17px;
}
.indexItem{
    display: flex;
    flex-direction: column;
}
.indexItem-title{
    text-align: center;
    height: 35px;
    line-height: 35px;
    background: #9DA6B2;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    border-top: 1px solid #CCCCCC;
}
.indexItem-title:first-child {
    border-top: none
}
.indexItem-content{
    display: flex;
    min-height: 33px;
    border-top: 1px solid #CCCCCC;
    justify-content: center;
    align-items: center;
}
.indexItem-content:last-child{
    border-bottom: 1px solid #CCCCCC;
}
.indexItem-content-left{
    background: #F2F3F5;
    min-width: 90px;
    text-align: center;
    line-height: 33px;
    border-right: 1px solid #CCCCCC;
    color: #0A2240;

    align-self: stretch;
}
.indexItem-content-right{
   flex: 1;
   padding-left: 8px;
}
