.PostRaceAnalysis-root{
    background: unset;
    /* color: #fff; */
    width: 100%;
}

.PostRaceAnalysisCollapse{
    
    color: #000;
}
.PostRaceAnalysis-sliderContainer{
    width: 100%;
    min-height: 213px;
}
.PostRaceAnalysis-headerTitle{
    font-weight: 700;
    font-size: 18px;
    margin: 0;
}


.PostRaceAnalysis-Card{
    background: #fff;
    border-radius: 8px;
    border-radius: 8px;
    box-shadow: 0px 2px 12px 0px #617ca833;
}

.PostRaceAnalysis-RDCNoData{
    height: 352px;
    background: #F7F7F7;
    border-radius: 8px;
    line-height: 352px;
}
.PostRaceAnalysis-Item-left{
    height: 60px;
    width: 60px;
    background: #E2EDF6;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 700;
}

.PostRaceAnalysis-Item-Right{
    text-align: left;
    display: flex;
    flex-direction: column;
}
.PostRaceAnalysis-Item-Right-Title{
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
}

.PostRaceAnalysis-Item-Right p {
    margin-bottom: 0;
}
.PostRaceAnalysis-Item-PosTime{
    display: flex;
    align-items: center;
}

.PostRaceAnalysis-RaceDetails{
    display: flex;
    justify-content: flex-end;
    color: #003C84;
    font-size: 13px;
    
}


.PostRaceAnalysis-RaceDetails div:last-child{
    padding-left: 3px;
}

.PostRaceAnalysis-Btn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #003C84;
    font-size: 13px;
}

.PostRaceAnalysis-Btn p{
    padding-left: 5px;
    font-weight: 700;
}

.PostRaceAnalysis-Btn div{
    margin-left: 30px;
}


.PostRaceAnalysis-Header {
    margin: 8px 0 12px 0;
    text-align: left;
    /* display: flex; */
    /* align-items: center; */
    /* flex: 1; */
  }
  
  .PostRaceAnalysis-Title {
    color: #0a2240;
    /* JC/Title/Title 1 - Bold */
    
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 127.778% */
  }
  
/* overwrite UI */
.PostRaceAnalysis-root .slick-track,
.PostRaceAnalysis-root .slick-list,
.PostRaceAnalysis-root .slick-slider{
    height: 100%
}

.PostRaceAnalysis-root .slick-dots{
    /* bottom: -40px;  */
}
.PostRaceAnalysis-root .slick-dots li.slick-active button:before {
    color: #003C84;
}

.PostRaceAnalysis-root .slick-dots li{
    margin: 0;
}

.PostRaceAnalysis-root {
    box-shadow: unset !important
}