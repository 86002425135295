.horse-tabs-wrapper-root {
  height: 100vh;
  background: #F2F3F5;
}
.horse-tabs-wrapper {
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  top: 0;
  box-shadow: 0px 4px 8px 0px #00000026;
  transition: all 0.3s ease;
}

.horse-tabs-wrapper button {
  font-size: 14px !important;
  min-height: 0;
  text-transform: none;
  color: #84909F;
  min-width: 42px;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: 400;
  text-transform: none;
}

.horse-tabs-wrapper .MuiTabs-flexContainer {
  padding: 0 12px;
  height: 40px;
}

.horse-tabs-wrapper .MuiTabs-flexContainer button:nth-of-type(n+2) {
  margin-left: 12px;
}

.horse-tabs-wrapper .Mui-selected {
  color: #836B55;
  /* font-weight: 700; */
  /* background-color: #003c84; */
}

.horse-tabs-wrapper .MuiTabs-root {
  min-height: 0;
}

.horse-tabs-wrapper .MuiTabs-indicator {
  height: 3px;
  background-color: #836B55;
}

.MuiTouchRipple-root {
  display: none;
}

.MuiCardActionArea-focusHighlight {
  display: none;
}

.notify-menu-title-wrapper {
  font-size: 22px;
  height: 48px;
  padding-bottom: 12px;
  /* background-size: cover;
  background-image: url("../../img/topImg.png");  display: flex; */
  background: linear-gradient(86.25deg, #0A2240 60.07%, #2265B4 100%),
  radial-gradient(105.33% 1607.26% at -1.73% 89.58%, #0A2240 0%, rgba(17, 44, 77, 0) 100%),
  radial-gradient(105.73% 1613.36% at 104.4% 11.98%, rgba(10, 34, 64, 0.1) 0%, rgba(0, 0, 0, 0) 100%); 
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.notify-menu-title-wrapper div:nth-of-type(n) {
  display: flex;
  align-items: center;
}

.title-horse-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.title-back {
  position: absolute;
  left: 10px;
  font-size: 13px;
  font-weight: 700;
  color: white;
}

.title-horse-name {
  font-size: 22px;
  margin-left: 8px;
  color: white;
}