.detail-menu-tabs-wrapper {
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 10;
  top: 0px;
  box-shadow: 0px 4px 8px 0px #00000026;
  transition: all 0.3s ease;
}
.detail-menu-tabs-wrapper button {
  font-size: 14px !important;
  min-height: 0;
  padding: 8px 12px;
  text-transform: none;
  color: #84909F;
  font-weight: 400;
  text-transform: none;
  
}
.detail-menu-tabs-wrapper .Mui-selected {
  color: #836B55;
  /* font-weight: 700; */
}
.detail-menu-tabs-wrapper .MuiTabs-root {
  min-height: 40px;
}
.detail-menu-tabs-wrapper .MuiTabs-indicator {
  height: 3px;
  background-color: #836B55;
}
.MuiTouchRipple-root {
  display: none;
}

.MuiCardActionArea-focusHighlight {
  display: none;
}
.detail-menu-title-wrapper {
  position: relative;
  height: 48px;
  padding-bottom: 12px;
  /* background-size: cover;
  background-image: url("../../../img/topImg.png"); */
  background: linear-gradient(86.25deg, #0A2240 60.07%, #2265B4 100%),
  radial-gradient(105.33% 1607.26% at -1.73% 89.58%, #0A2240 0%, rgba(17, 44, 77, 0) 100%),
  radial-gradient(105.73% 1613.36% at 104.4% 11.98%, rgba(10, 34, 64, 0.1) 0%, rgba(0, 0, 0, 0) 100%); 
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.detail-menu-title-wrapper div:nth-of-type(n) {
  display: flex;
  align-items: center;
}
.title-horse-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.detail-title-back {
  position: absolute;
  left: 10px;
  font-size: 13px;
  font-weight: 700;
  color: white;
}
.detail-title-horse-name {
  margin-left: 8px;
  font-size: 22px;
  font-weight: 700;
  color: white;
}
#menuTab .MuiTabs-flexContainer,
#menuTab .MuiTabs-scroller {
  height: 40px;
}