.Base {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  z-index: 1;
}
.Slip{
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index:1;
  text-align: left;
  color: var(--jc-neutrals-black, #000);
  height: 100%;
  width: 100%;
  background: var(--jc-neutrals-white, #FFF);
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.10);
  border-radius: 24px 24px 0px 0px;
}
section {
  width: 100%;
}
.BackgroundLeft {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}
.BackgroundRight {
  top: 200px;
  right: 0;
  position: fixed;
  z-index: 0;
}

.TopContainer {
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.avatar {
  width: 124px;
  height: 124px;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
  border: 3px solid rgba(255, 255, 255);
  margin-bottom: 8px;
}
.avatarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  margin: 1rem 1rem 0;
  position: relative;
}
#Profile_OwnerType {
  bottom: 10px;
  position: absolute;
  right: 0px;
  width: 40px;
}

.NameText {
  color: var(--jc-neutrals-white, #fff);
  /* JC/Display/Large - Bold */
  text-transform: Uppercase;
  margin: 6px;
}
.SubText {
  display: flex;
  align-items: center;
  color: var(--jc-neutrals-white, #fff);
  letter-spacing: 0.011px;
}
.divider-wrapper {
  /* width: 100%;
  z-index: 1;
  background-color: white; */
  padding: 0 12px 16px 12px;
  z-index: 1;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.divider {
  width: calc(100% - 8px);

  height: 1px;
  background-color: #CED3D9;
}
