.Base{
    padding: 0 12px 0 12px;
    flex: 1;
    color: #0A2240;
}
.title{
    display: flex;
    padding-bottom: 12px;
    gap: 12px;
    align-self: stretch;

    /* JC/Title/Title 1 - Bold */
    
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 127.778% */
}
.CardBase{
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--radius-md, 8px);
    align-self: stretch;
    border-radius: var(--radius-md, 8px);
    background: var(--color-neutrals-white, #FFF);
    box-shadow: 0px 2px 12px 0px rgba(97, 124, 168, 0.20);
}
.CardTitle{
    align-self: stretch;
    /* JC/Label/Label 1 - Bold */
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.1px;
    text-align: left;
}
.CardContent{
    /* JC/Body/Body 1 - Regular */
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 131.25% */
    text-align: left;
}