.Base{
    background: var(--jc-neutrals-white, #FFF);
    width: 100%;
    height: auto;
    display: flex;
    flex: 1;   
    flex-direction: column;
    padding-bottom: 16px;
    z-index: 1;
    color: #0a2240;
}
.Header{
    margin: 8px 16px 12px 16px;
    display: flex;
    align-items: center;
    flex: 1;
}

.Title{
    color: #0A2240;
    /* JC/Title/Title 1 - Bold */
    
    font-size: 22px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 127.778% */
}
.More{
    color: #836B55;
    text-align: right;

    /* JC/Label/Label 2 - Medium */
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 15.6px */
}

.BoxShadow {
    border-radius: 8px;
    box-shadow: 0px 2px 12px 0px #617CA833;
}