.App {
  text-align: center;
  background-image: url('./img/BackgroundBlue.svg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: rgba(10, 34, 64, 1);
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: constant(safe-area-inset-top); /* Fallback for older browsers */
  padding-top: env(safe-area-inset-top);
  -webkit-touch-callout: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.BackgroundLeft{
  position: fixed;
  top: 0;
  left: 0;
  z-index:0;
}
.BackgroundRight{
  top: 200px;
  right: 0;
  position: fixed;
  pointer-events: none;
  z-index:0;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color:#012E65;
  height: 47px;
  flex-shrink: 0;;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.App-link {
  color: #61dafb;
}
a {
  text-decoration: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
