.RPCompare-root {
    /* height: 100%; */
}

.RPCompare-content{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.RPCompare-label{
    text-align: left;
}

.RPCompare-select{

}

.RPCompare-select .MuiSelect-select{
    padding: 4px 0 4px 10px;
    color: #836B55;
}

.RPCompare-Arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 14px 0;
}
.RPCompare-Arrow span{
    /* padding: 0 20px; */
    width: 150px;
}
.RPCompare-Arrow-Item{
    align-items: center;
    display: flex;
    background: linear-gradient(221.19deg, #9F9579 4.72%, #CEB298 98.88%, rgba(159, 139, 121, 0) 98.89%);
    border-radius: 20px;
    color: #fff;
}

.CompareHorse {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #836B55;
    border-radius: 5px;
    height: 28px;
    font-size: 16px;
    color: #836B55;
    margin-top: 5px;
    padding: 0px 5px;
}
/* .CompareHorse svg{
    position: absolute;
    right: 5px;
} */
.CompareHorse p{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.Arrow-disable {
    background: linear-gradient(221.19deg, #84909F 4.72%, #CED3D9 98.89%);
    color: #CED3D9;
}
.RP-square-container{
    display: flex;
    font-size: 11px;
    margin-top: 4px;
}
.RP-square-Reminder{
    font-size: 11px;
    margin-top: 12px;
    text-align: left;
}
.RP-square {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin: 3px 5px 0 0;
    background: #0ABEF9;
}
.RP-square-2{
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin: 3px 5px 0 0;
    background: rgb(215, 77, 218);
}
.RP-square-text{
    text-align: left;
    width: 250px;
}
.RPCompare-noData {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(247, 247, 247, 1);
    border-radius: 8px;
    padding: 0 16px;
    width: 100%;
    /* height: calc(100% - 45px); */
    height: 100%;
    font-size: 14px;
}