.demo-container {
  z-index: 10;
  padding: 0 20px;
}
.tabs-menu-wrapper {
  opacity: 0;
  display: flex;
  position: fixed;
  top: calc(47px + 1rem);
  left: 0;
  overflow-x: auto;
  padding: 0 16px;
  transition: opacity 0.5s ease-in-out;
}
.taps-menu-item {
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 16px;
  padding: 4px 8px;
}
.tabs-menu-wrapper :nth-child(n + 2) {
  margin-left: 16px;
}
.content {
  height: 1200px;
  border-radius: 8px;
  background-color: #3d9bc4;
  margin-top: 32px;
  font-size: 32px;
  padding: 0 8px;
  color: white;
  font-weight: 600;
  text-align: left;
}
